<template>
  <v-container>
    <ApprovalTable
      ref="ApprobalTable"
      :keys="keys"
      :public_key="public_key"
      @edit_item="edit_item($event)"
    ></ApprovalTable>
    <ApprovalForm
      ref="ApprovalForm"
      @get_items="get_items()"
      :keys="keys"
      :public_key="public_key"
    ></ApprovalForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import ApprovalTable from "@/components/employees_aprovall/table";
import ApprovalForm from "@/components/employees_aprovall/form";
export default {
  name: "employees_approval",
  data() {
    return {
      item_index: -1,
      item: null,
      menu: false,
      status: false,
      search: "",
      toggle_form: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      tbody: [
        {
          employee_name: "hassa ahmed",
          desc: "asdasdasd as",
          date: "2020-20-20",
        },
        {
          employee_name: "hassa ahmed",
          desc: "asdas d asdas",

          date: "2020-20-20",
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: !this.$store.state.ar ? this.keys.employee_name : " اسم الموظف",
          value: "employee_name",
        },
        {
          text: !this.$store.state.ar
            ? this.keys.employee_type
            : " تاريخ الموافقة",
          value: "date",
        },
        {
          text: !this.$store.state.ar ? this.keys.city : "وصف الموافقة",
          value: "desc",
        },
      ];
    },
    keys() {
      return this.$store.getters.lang("employees");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
  },
  methods: {
    get_items() {
      this.$refs.ApprobalTable.get_items();
    },
    edit_item(ev) {
      this.$refs.ApprovalForm.edit_item(ev);
    },
  },
  components: {
    ApprovalForm,
    ApprovalTable
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    thead {
      background-color: #11556b;
      th {
        color: #fff !important;
        font-weight: 500;
      }
    }
  }
</style>
