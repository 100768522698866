<template>
  <div>
    <v-skeleton-loader
      type="table"
      class="mt-10"
      v-if="!table_loading"
    ></v-skeleton-loader>
    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ التقرير</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>

    <v-row class="mt-10" v-if="table_loading">
      <v-col cols="9">
        <h3 class="font-weight-medium">
          {{
            !$store.state.ar
              ? keys.report_status
              : "حالة التقرير لم يتم ارساله للموافقة بعد"
          }}
        </h3>
      </v-col>
      <v-col cols="3" class="text-end">
        <v-btn
          @click="send_report()"
          color="primary"
          class="me-3"
          :disabled="!reports.can_send_report"
        >
          {{
            !$store.state.ar ? keys.send_approval_btn : "ارسال الموافقة"
          }}</v-btn
        >
      </v-col>
    </v-row>

    <v-card class="mt-10" v-if="table_loading">
      <v-card-title>
        <div>
          <v-btn icon>
            <img src="@/assets/icons/xl.png" width="30" />
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <span>
          0 {{ !$store.state.ar ? keys.total_hours : "اجمالى الساعات" }}</span
        >
      </v-card-title>

      <v-data-table
        class="text-center"
        :headers="headers"
        :items-per-page="5"
        :search="search"
        :items="reports.data"
        mobile-breakpoint="0"
        :no-data-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        :no-results-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        loading-text="جارى التحميل"
        :footer-props="this.$store.state.dataTableFooterProps"
      >
        <template slot="body.prepend">
          <tr>
            <td colspan="14">
              <v-text-field
                class="pa-0 ma-0 mb-4"
                hide-details
                :label="!$store.state.ar ? public_key.search_input : 'بحث'"
                v-model="search"
                append-icon="search"
                full-width
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              <div class="d-flex align-center">
                <v-checkbox
                  v-if="reports.can_send_report"
                  @change="row_collapse(item, index)"
                  v-model="item.done"
                  on-icon="check_circle"
                  off-icon="radio_button_unchecked"
                  hide-details
                  class="rounded mt-0"
                ></v-checkbox>
                <v-btn v-if="reports.can_send_report" icon small>
                  <v-icon small>delete</v-icon>
                </v-btn>
                <v-checkbox
                  v-if="reports.can_send_report"
                  @change="row_collapse(item, index)"
                  v-model="item.done"
                  on-icon="edit"
                  off-icon="edit"
                  hide-details
                  dense
                  class="rounded mt-0"
                ></v-checkbox>
                <span>{{ index + 1 }}</span>
              </div>
            </td>
            <td>{{ item.report_date.slice(item.report_date.indexOf(" ")) }}</td>
            <td>{{ item.report_day }}</td>
            <td>{{ item.start_time }}</td>
            <td>{{ item.end_time }}</td>
            <td>{{ item.goal.name }}</td>
            <td>{{ item.goal_type.name }}</td>
            <td>{{ item.operation.name }}</td>
            <td>{{ item.operation_description }}</td>
            <td>{{ item.subscribers }}</td>
            <td>{{ item.files }}</td>
            <td>{{ item.total_kilometers }}</td>
            <td>{{ item.notes }}</td>
          </tr>
          <tr v-if="item.done">
            <td colspan="14">
              <v-form :ref="`form${index}`" class="py-5">
                <v-row class="mb-4">
                  <v-col cols="6" sm="3">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="item.report_date"
                      width="290px"
                      >{{
                        !$store.state.ar ? keys.total_hours : "اجمالى الساعات"
                      }}
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :rules="rules.required"
                          v-model="item.report_date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          min-width="200"
                        >
                          <template slot="label">
                            <strong class="error--text">*</strong>
                            {{ !$store.state.ar ? keys.date : "التاريخ" }}
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="item.report_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">
                          الغاء
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(item.report_date)"
                        >
                          حفظ
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!--/ col (date) -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      :label="!$store.state.ar ? keys.day : 'اليوم'"
                      disabled
                      :value="days[index]"
                    ></v-text-field>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-model="item.start_time"
                      prepend-icon="mdi-clock-time-four-outline"
                      type="text"
                      :rules="rules.start_time"
                      @input="
                        check_time({
                          value: $event,
                          prop: 'start_time',
                          index: index,
                        })
                      "
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{
                          !$store.state.ar ? keys.start_time : "بداية  الوقت"
                        }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      :rules="rules.end_time"
                      v-model="item.end_time"
                      prepend-icon="mdi-clock-time-four-outline"
                      type="text"
                      @input="
                        check_time({
                          value: $event,
                          prop: 'end_time',
                          index: index,
                        })
                      "
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.end_time : "نهاية  الوقت" }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-model="total_hours[index]"
                      type="number"
                      disabled
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{
                          !$store.state.ar ? keys.total_hours : "اجمالى الساعات"
                        }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-select
                      :rules="rules.required"
                      :loadeing="goals.length == 0"
                      :items="goals"
                      item-value="id"
                      v-model="item.goal.id"
                      item-text="name"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.target : "الهدف" }}
                      </template>
                    </v-select>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-select
                      :rules="rules.required"
                      :loadeing="goals_types.length == 0"
                      :items="goals_types"
                      item-value="id"
                      item-text="name"
                      v-model="item.goal_type.id"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.target_type : "نوع الهدف" }}
                      </template>
                    </v-select>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      :rules="rules.required"
                      :value="item.operation.name"
                      :label="!$store.state.ar ? keys.potency : 'الفاعلية'"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.potency : "الفاعلية" }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" sm="3">
                    <v-select
                      :rules="rules.required"
                      :items="operations"
                      v-model="item.operation.id"
                      item-text="name"
                      item-value="id"
                      :loading="operations.length == 0"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{
                          !$store.state.ar ? keys.potency_type : "نوع الفاعلية"
                        }}
                      </template>
                    </v-select>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-select
                      :rules="rules.members"
                      multiple
                      :items="members"
                      item-value="id"
                      item-text="name"
                      v-model="item.members"
                      :loading="members.length == 0"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.subscribers : "المشتركين" }}
                      </template>
                    </v-select>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      readonly
                      type="text"
                      :value="item.images.length"
                      :label="!$store.state.ar ? public_key.files : 'مرفقات'"
                      @click="
                        $emit('open_attach_dialog', {
                          table: true,
                          images: item.images,
                        })
                      "
                    >
                      <template slot="append-outer">
                        <v-btn  fab small depressed color="primary">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" sm="3">
                    <v-textarea
                      rows="1"
                      :label="!$store.state.ar ? keys.details : 'التفاصيل'"
                      :value="item.notes"
                    ></v-textarea>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" sm="3">
                    <v-text-field
                      :rules="rules.required"
                      :value="item.travel_from"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.travel_form : "السفر من " }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" sm="3">
                    <v-text-field
                      :rules="rules.required"
                      :label="!$store.state.ar ? keys.travel_to : 'السفر الى '"
                      :value="item.travel_to"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ !$store.state.ar ? keys.travel_to : "السفر الى " }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->

                  <v-col cols="6" sm="3">
                    <v-text-field
                      :rules="rules.required"
                      :value="item.total_kilometers"
                      type="number"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{
                          !$store.state.ar ? keys.total_km : "اجمالى الكيلومتر"
                        }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/col -->
                </v-row>
                <div class="text-end">
                  <v-btn
                    :loading="item.loading"
                    :disabled="item.loading"
                    @click="save(item, index)"
                    class="me-2"
                    color="lightGray"
                    outlined
                  >
                    <v-icon class="me-1" color="primary">save</v-icon>
                    {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
                  </v-btn>
                  <v-btn class="me-2" color="lightGray" outlined>
                    <v-icon color="primary" class="me-1">delete</v-icon>
                    {{ !$store.state.ar ? public_key.reset_btn : "حذف" }}
                  </v-btn>
                  <v-btn class="me-2" color="lightGray" outlined
                    >........</v-btn
                  >
                  <v-btn class="me-2" color="lightGray" outlined
                    >........</v-btn
                  >
                </div>
              </v-form>
            </td>
          </tr>
        </template>

        <template slot="footer.prepend">
          <div class="me-2">
            <v-chip small color="gray"
              >{{ keys_employees.total_h }} : {{reports.total_hours}}</v-chip
            >
          </div>
          <div class="me-2">
            <v-chip small color="gray"
              >{{ keys_employees.total_travel }} :  {{reports.total_kilometers}}
              {{ keys_employees.km }}</v-chip
            >
          </div>
          <div class="me-2">
            <v-chip small color="gray"
              >{{ keys_employees.total_protocol }} : {{reports.protocols}}</v-chip
            >
          </div>
          <div class="me-2">
            <v-chip small color="gray"
              >{{ keys_employees.total_aprovels }} : {{reports.agreements_no}}</v-chip
            >
          </div>
        </template>
      </v-data-table>
      <!--/ v-card (table )-->
    </v-card>
  </div>
</template>

<script>
export default {
  props: [
    "public_key",
    "keys",
    "keys_employees",
    "goals",
    "goals_types",
    "operations",
    "members",
    "check_params",
    "table_attachments",
  ],
  name: "Table",
  data() {
    return {
      selected_index: -1,
      snackbar: false,
      snackbar_err: false,
      search: "",
      modal: false,
      rules: {
        required: [(v) => !!v || "مطلوب"],
        members: [(v) => !(v.length == 0) || "مطلوب"],
        start_time: [
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
        ],
        end_time: [
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
        ],
      },
      reports: {
        data: [],
      },
      table_loading: false,
      users: [],
    };
  },
  computed: {
    days() {
      let day = [];
      this.reports.data.forEach((e) => {
        let date = new Date(e.report_date);
        let num_day = date.getDay();
        switch (num_day) {
          case 1:
            day.push("الأثنين");
            break;
          case 2:
            day.push("الثلاثاء");
            break;
          case 3:
            day.push("الأربعاء");

            break;
          case 4:
            day.push("الخميس");

            break;
          case 5:
            day.push("الجمعة");

            break;
          case 6:
            day.push("السبت");

            break;
          default:
            day.push("الأحد");
            break;
        }
      });

      return day;
    },
    total_hours() {
      let total = [];
      this.reports.data.forEach((e) => {
        let start_time = new Date(
          `${e.report_date} ${e.start_time}:00`
        ).getHours();
        let end_time = new Date(`${e.report_date} ${e.end_time}:00`).getHours();
        total.push(end_time - start_time);
      });
      return total;
    },

    headers() {
      return [
        {
          text: !this.$store.state.ar ? this.keys.date : "اجراءات",
          value: "actions",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.date : "تاريخ",
          value: "date",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.day : "اليوم",
          value: "day",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.start_time : "من ساعة",
          value: "start_time",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.end_time : "الى ساعة",
          value: "end_time",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.target : "الهدف",
          value: "target",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.target_type : "نوع الهدف",
          value: "target_type",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.potency : "الفعالية ",
          value: "operation.name",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.potency_type : "نوع العملية",
          value: "operation_description",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.keys.subscribers : "المشتركين",
          value: "subscribers",
          align: "center",
          sortable: false,
        },
        {
          text: !this.$store.state.ar ? this.public_key.files : "الملقات",
          value: "files",
          align: "center",
          sortable: false,
        },
        // {
        //   text: !this.$store.state.ar ? this.public_key.protocol : "بروتوكول",
        //   value: "protocol",
        //   align: "center",
        // },
        {
          text: !this.$store.state.ar ? this.keys.km : "الكيلومتر",
          value: "total_kilometers",
          align: "center",
        },
        {
          text: !this.$store.state.ar ? this.keys.details : "تفاصيل",
          value: "notes",
          align: "center",
        },
      ];
    },
  },
  methods: {
    row_collapse(item, ind) {
      this.selected_index = ind;
      this.$emit("fill_protocol", {
        protocol: item.protocol,
        agreements: item.agreements,
        show: item.done,
      });
      for (let i = 0; i < this.reports.data.length; i++) {
        if (i != ind && this.reports.data[i].done) {
          this.reports.data[i].done = false;
          break;
        }
      }
    },
    check_time(ev) {
      if (ev.value.length == 2) {
        this.reports.data[ev.index][ev.prop] =
          this.reports.data[ev.index][ev.prop] + ":";
      }
    },
    get_reports() {
      const formData = new FormData();
      if (this.check_params) {
        formData.append("user_id", this.$route.query.id);
        formData.append(
          "date",
          this.$route.query.date.slice(
            0,
            this.$route.query.date.lastIndexOf("-")
          )
        );
      }
      this.$store
        .dispatch("public__request", {
          config: {
            url: "reports",
            method: "get",
          },
          data: this.check_params ? formData : [],
        })
        .then((res) => {
          this.reports = res.data;
          this.table_loading = true;
          this.$emit("is_send", res.data.can_send_report);
          console.log(this.reports);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    add_files_length() {
      this.table_attachments.forEach((e, i) => {
        this.reports.data[this.selected_index].images.push(e);
      });
    },

    save(item, index) {
      let report = {
        report_date: item.report_date,
        start_time: item.start_time,
        end_time: item.end_time,
        goal_id: item.goal.id,
        goal_type_id: item.goal_type.id,
        operation_id: item.operation.id,
        total_kilometers: item.total_kilometers,
        operation_description: item.operation_description,
        notes: item.notes,
        travel_from: item.travel_from,
        travel_to: item.travel_to,
        report_day: this.days[index],
        members: item.members,
      };
      if (this.$refs[`form${index}`].validate()) {
        this.reports.data[index].loading = true;

        const formData = new FormData();
        Object.keys(report).forEach((e, i) => {
          if (e == "members") {
            report.members.forEach((m, s) => {
              formData.append(`members[${s}]`, m);
            });
          } else if (e == "start_time") {
            formData.append(
              "start_time",
              `${report.report_date} ${report[e]}:00`
            );
          } else if (e == "end_time") {
            formData.append(
              "end_time",
              `${report.report_date} ${report[e]}:00`
            );
          } else {
            formData.append(e, report[e]);
          }
        });
        this.table_attachments.forEach((e, i) => {
          formData.append(`attachments[${i}]`, e);
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: `reports/${item.id}`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.reports.data[index].loading = false;
            this.snackbar = true;
            this.$emit("save_report");
            console.log(res);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    send_report() {},
  },
  mounted() {
    this.get_reports();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  thead.v-data-table-header {
    background-color: #11556b;
    th {
      color: #fff !important;
      font-weight: 500;
      vertical-align: center !important;
    }
  }
}
</style>
