<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="8">
        <div>
          <h3>דייוח ליחידות שכר</h3>
          <p>
            {{ !$store.state.ar ? keys.last_date : "اخر ادخال بتاريخ" }}
            26.04.2022
          </p>
        </div>
      </v-col>
      <!--/ col -->
      <v-col cols="4">
        <v-dialog
          ref="dialog"
          v-model="modal2"
          :return-value.sync="date_month"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_month"
              prepend-icon="mdi-calendar"
              readonly
              :label="!$store.state.ar ? keys.month : 'الشهر'"
              v-bind="attrs"
              v-on="on"
              min-width="200"
            ></v-text-field>
          </template>
          <v-date-picker
            selected-items-text
            type="month"
            v-model="picker"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal2 = false"> الغاء </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date_month)">
              حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!--/ col -->
    </v-row>
    <!--/ row  -->

    <EmployeeInfo
      :check_params="check_params"
      :keys="keys"
      :goals_types="goals_types"
    ></EmployeeInfo>

    <Table
      ref="report_table"
      :public_key="public_key"
      :goals="goals"
      :goals_types="goals_types"
      :operations="operations"
      :members="members"
      :keys="keys"
      :check_params="check_params"
      :keys_employees="keys_employees"
      @open_attach_dialog="open_attach_dialog($event)"
      @is_send="($event)=> is_send = $event"
      @save_report="save_report()"
      @fill_protocol="fill_protocol($event)"
      :table_attachments="table_attachments"
    ></Table>

    <Protocols
      @close_protocol="protocol_prop = false"
      v-if="protocol_prop"
      :protocols="protocols"
      :agreements="agreements"
      :public_key="public_key"
      :keys="keys"
    ></Protocols>

    <ReportForm
      :is_send="is_send"
      :goals="goals"
      :goals_types="goals_types"
      :keys="keys"
      :operations="operations"
      :members="members"
      :keys_employees="keys_employees"
      :create_attachments="create_attachments"
      @open_attach_dialog="open_attach_dialog($event)"
      @save_report="save_report()"
      :public_key="public_key"
    ></ReportForm>

    <attachments
      @close_dialog="close_dialog()"
      @save_attach="save_attach($event)"
      :files="files"
      :dialog="dialog"
    ></attachments>
  </v-container>
  <!--/ container -->
</template>

<script>
import Table from "@/components/employee_reports/table";
import ReportForm from "@/components/employee_reports/report_form";
import Protocols from "@/components/employee_reports/protocols";
import EmployeeInfo from "@/components/employee_reports/employee_info";
import Attachments from "@/components/employee_reports/attachments";

export default {
  name: "employee",
  data() {
    return {
      is_send:null,
      protocol_prop: false,
      agreements: null,
      protocols: {
        members: "",
        topics: "",
        notes: "",
      },
      goals_types: [],
      goals: [],
      operations: [],
      date_month: new Date().toISOString().substr(0, 7),
      modal2: false,
      dialog: false,
      files: [],
      picker: new Date("1989-07-28").toISOString().substr(0, 10),
      reports: [],
      members: [],
      is_table: false,
      create_attachments: [],
      table_attachments: [],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.lang("employee");
    },
    keys_employees() {
      return this.$store.getters.lang("employees");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
    check_params() {
      if (this.$route.query.id && this.$route.query.date) {
        return true;
      }
      return false;
    },
  },
  components: {
    Table,
    ReportForm,
    Protocols,
    EmployeeInfo,
    Attachments,
  },
  methods: {
    check_time(ev) {
      if (this.time.length == 2) {
        this.time = this.time + ":";
      }
    },
    get_goals() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "goals",
            method: "get",
          },
        })
        .then((res) => {
          this.goals = res.data.data;
        });
    },
    get_types() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "goalTypes",
            method: "get",
          },
        })
        .then((res) => {
          this.goals_types = res.data.data;
        });
    },
    get_operations() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "operations",
            method: "get",
          },
        })
        .then((res) => {
          this.operations = res.data.data;
        });
    },
    get_members() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "members",
            method: "get",
          },
        })
        .then((res) => {
          this.members = res.data.data;
        });
    },
    open_attach_dialog(ev) {
      this.is_table = ev.table;
      this.files = Object.assign([], ev.images);
      this.dialog = true;
    },
    close_dialog() {
      this.dialog = false;
      this.table_attachments = Object.assign([]);
      this.create_attachments = Object.assign([]);
    },
    save_attach(ev) {
      if (this.is_table) {
        this.table_attachments = Object.assign([], ev.attach);
        // this.$refs.report_table.add_files_length();
      } else {
        this.create_attachments = Object.assign([], ev.attach);
      }
      this.dialog = false;
    },

    save_report() {
      this.$refs.report_table.get_reports();
    },
    fill_protocol(ev) {
      if (ev.protocol) {
        this.protocols = ev.protocol;
      } else {
        this.protocols = {
          members: "",
          topics: "",
          notes: "",
        };
      }
      this.agreements = ev.agreements;
      this.protocol_prop = ev.show;
    },
  },
  mounted() {
    this.get_goals();
    this.get_types();
    this.get_operations();
    this.get_members();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  thead {
    background-color: #12457c;
    th {
      color: #fff !important;
      font-weight: 500;
    }
  }
}
</style>
