var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.table_loading)?_c('v-skeleton-loader',{staticClass:"mt-10",attrs:{"type":"table"}}):_vm._e(),(_vm.table_loading)?_c('v-card',{staticClass:"mt-10"},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","items":_vm.reports,"items-per-page":5,"search":_vm.search,"headers":_vm.headers,"no-data-text":!_vm.$store.state.ar ? _vm.public_key.table_empty : 'لايوجد بيانات',"no-results-text":!_vm.$store.state.ar ? _vm.public_key.table_empty : 'لايوجد بيانات',"loading-text":"جارى التحميل","footer-props":this.$store.state.dataTableFooterProps},scopedSlots:_vm._u([{key:"item.employee",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
            name: 'employee',
            query: { id: item.employee.id, date: item.date },
          },"icon":""}},[_c('v-icon',[_vm._v(" visibility ")])],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","depressed":"","fab":"","color":"success"},on:{"click":function($event){return _vm.toggle_status(item, 'status')}}},[_c('v-icon',[_vm._v(" check ")])],1),_c('v-btn',{staticClass:"ms-2",attrs:{"x-small":"","depressed":"","fab":"","color":"error"},on:{"click":function($event){return _vm.toggle_status(item, 'status')}}},[_c('v-icon',[_vm._v(" close ")])],1)]}},{key:"item.paid",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"dense":"","color":"success","label":item.paid
              ? _vm.keys.employee_status_active
              : _vm.keys.employee_status_inactive},on:{"click":function($event){return _vm.toggle_status(item, 'paid')}},model:{value:(item.paid),callback:function ($$v) {_vm.$set(item, "paid", $$v)},expression:"item.paid"}})]}}],null,true)},[_c('template',{slot:"body.prepend"},[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('v-text-field',{staticClass:"pa-0 ma-0 mb-4",attrs:{"hide-details":"","label":!_vm.$store.state.ar ? _vm.public_key.search_input : 'بحث',"append-icon":"search","full-width":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }