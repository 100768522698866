<template>
  <v-container>
    <v-skeleton-loader class='mt-10' type="table" v-if="!table_loading"></v-skeleton-loader>
    <v-card class="mt-10" v-if="table_loading">
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="protocols"
        :items-per-page="5"
        :no-data-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        :no-results-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        loading-text="جارى التحميل"
        :footer-props="this.$store.state.dataTableFooterProps"
      >
        <template slot="body.prepend">
          <tr>
            <td colspan="11">
              <v-text-field
                class="pa-0 ma-0 mb-4"
                hide-details
                :label="!$store.state.ar ? public_key.search_input : 'بحث'"
                v-model="search"
                append-icon="search"
                full-width
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:[`item.members`]="{ item }">
          {{
            item.members.length > 15
              ? item.members.slice(0, 15) + "..."
              : item.members
          }}
        </template>
        <template v-slot:[`item.topics`]="{ item }">
          {{
            item.topics.length > 15
              ? item.topics.slice(0, 15) + "..."
              : item.topics
          }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          {{
            item.notes.length > 15
              ? item.notes.slice(0, 15) + "..."
              : item.notes
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="show_item(item)">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!--/ v-card (table )-->
    </v-card>
    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>عرض البيانات </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <div>
            <h4 class='black--text mb-2'>المستخدمين</h4>
            <p class="mb-2">{{ item.members }}</p>
          </div>
          <v-divider class="my-4"></v-divider>
          <div>
            <h4 class='black--text mb-2'>المواضيع</h4>
            <p class="mb-2">{{ item.topics }}</p>
          </div>
          <v-divider class="my-4"></v-divider>
          <div>
            <h4 class='black--text mb-2'>الملخص</h4>
            <p class="mb-2">{{ item.notes }}</p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="dialog = false" text color="red"> الغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "protocols",
  data() {
    return {
      table_loading:false,
      dialog: false,
      search: "",
      protocols: [],
      item: {
        members: "",
        topics: "",
        notes: "",
      },
    };
  },
  computed: {
    keys() {
      return this.$store.getters.lang("protoclos");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
    headers() {
      return [
        {
          text: !this.$store.state.ar ? this.keys.date : "المستخدمين",
          value: "members",
          align: "center",
        },
        {
          text: !this.$store.state.ar ? this.keys.description : "المواضيع",
          value: "topics",
          align: "center",
        },
        {
          text: !this.$store.state.ar ? this.keys.files_count : "الملخص",
          value: "notes",
          align: "center",
        },
        {
          text: !this.$store.state.ar ? this.keys.files_count : "اجراءات",
          value: "actions",
          align: "center",
        },
      ];
    },
  },
  components: {},
  methods: {
    get_protocols() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "protocols",
            method: "get",
          },
        })
        .then((res) => {
          this.protocols = res.data.data;
          this.table_loading = true;
        });
    },
    show_item(item) {
      this.item = Object.assign({}, item);
      this.dialog = true;
    },
  },
  mounted() {
    this.get_protocols();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  thead {
    background-color: #11556b;
    th {
      color: #fff !important;
      font-weight: 500;
    }
  }
}
</style>
