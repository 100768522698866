<template>
  <v-dialog
    transition="dialog-bottom-transition"
    hide-overlay
    fullscreen
    :value="dialog"
  >
    <v-card class="rounded-0">
      <v-card-title class="font-weight-bold">
        الملفات
        <v-spacer></v-spacer>
        <v-btn @click="close_dialog()" icon small>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-file-input
          hide-details
          v-model="input_file"
          ref="upload_input"
          multiple
        ></v-file-input>

        <v-row class="mt-5">
          <v-col
            v-for="(file, ind) in files"
            :key="ind"
            cols="6"
            sm="2"
            class="position-relative text-center"
          >
            <v-card outlined>
              <v-card-text>
                <img
                  v-if="files_types[ind] == 'pdf'"
                  src="@/assets/files_icon/pdf-file.png"
                  width="100"
                />
                <img
                  v-else-if="
                    files_types[ind] == 'docx' || files_types[ind] == 'doc'
                  "
                  width="100"
                  src="@/assets/files_icon/doc.png"
                />
                <img
                  v-else-if="
                    files_types[ind] == 'png' ||
                    files_types[ind] == 'jpg' ||
                    files_types[ind] == 'jpeg' ||
                    files_types[ind] == 'jfif' ||
                    files_types[ind] == 'WebP'
                  "
                  width="100"
                  src="@/assets/files_icon/image.png"
                />
                <img v-else width="100" src="@/assets/files_icon/file.png" />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.54)"
                  @click="
                    $store.commit(
                      'download_file',
                      `${$store.state.def_path}/${file.image}`
                    )
                  "
                  icon
                >
                  <v-icon>file_download</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  :href="`${$store.state.def_path}/${file.image}`"
                  icon
                  v-if="
                    files_types[ind] == 'png' ||
                    files_types[ind] == 'jpg' ||
                    files_types[ind] == 'jpeg' ||
                    files_types[ind] == 'jfif' ||
                    files_types[ind] == 'WebP' ||
                    files_types[ind] == 'pdf'
                  "
                >
                  <v-icon>visibility</v-icon>
                </v-btn>
                <v-btn @click="delete_file(file.id, ind)" icon>
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save_attach()" class="me-2" color="lightGray" outlined>
          <v-icon small class="me-1" color="primary">save</v-icon>
          حفظ
        </v-btn>
        <v-btn @click="close_dialog()" class="me-2" color="lightGray" outlined>
          <v-icon small class="me-1" color="primary">close</v-icon>
          الغاء
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog", "files"],
  name: "Attachments",
  data() {
    return {
      input_file: [],
    };
  },
  computed: {
    files_types() {
      let files = this.files;
      let types = [];
      files.forEach((e, i) => {
        types.push(e.image.slice(e.image.lastIndexOf(".") + 1));
      });
      return types;
    },
  },
  methods: {
    change_dialog(v) {
      console.log(v);
    },
    save_attach() {
      this.$emit("save_attach", { attach: this.input_file });
    },
    delete_file(id, ind) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `images/user/${id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.files.splice(ind, 1);
        });
    },
    close_dialog() {
      this.input_file = [];
      this.$emit("close_dialog");
    },
  },
};
</script>
