<template>
  <div>
    <v-skeleton-loader
      v
      type="table"
      class="mt-10"
      v-if="tbody.length == 0"
    ></v-skeleton-loader>
    <v-card v-if="tbody.length != 0" class="mt-10">
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items-per-page="10"
        :search="search"
        :items="tbody"
        :no-data-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        :no-results-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        loading-text="جارى التحميل"
        :footer-props="this.$store.state.dataTableFooterProps"
      >
        <template slot="body.prepend">
          <tr>
            <td colspan="6">
              <v-text-field
                class="pa-0 ma-0 mb-4"
                hide-details
                :label="!$store.state.ar ? public_key.search_input : 'بحث'"
                v-model="search"
                append-icon="search"
                full-width
              ></v-text-field>
            </td>
          </tr>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            dense
            :label="
              item.status
                ? keys.employee_status_active
                : keys.employee_status_inactive
            "
            :color="item.status ? 'success' : 'red lighten-3'"
            v-model="item.status"
          >
          </v-switch>
        </template>
        <!--/ employee status -->

        <template v-slot:[`item.actions`]="{ item, index }">
          <v-btn @click="edit_employee(item, index)" icon small>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn icon small @click="get_delete_id(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ v-card (table )-->
    </v-card>
    <!--/ table -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف الموظف ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_employee()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </div>
</template>

<script>
export default {
  name: "employeesTable",
  props: ["keys", "public_key"],
  data() {
    return {
      search: "",
      delete_id: null,
      delete_dialog: false,
      delete_loader: false,
      users: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: !this.$store.state.ar ? this.keys.employee_name : " اسم الموظف",
          value: "name",
        },
        {
          text: !this.$store.state.ar ? this.keys.employee_type : " نوع الموظف",
          value: "type",
        },
        {
          text: !this.$store.state.ar ? this.keys.city : "البلد",
          value: "city",
        },
        {
          text: !this.$store.state.ar
            ? this.keys.creation_date
            : "تاريخ الاضافة",
          value: "createdAt",
        },
        {
          text: !this.$store.state.ar ? this.keys.status : "الحالة",
          value: "status",
        },
        {
          text: !this.$store.state.ar ? this.keys.status : "اجراءات",
          value: "actions",
        },
      ];
    },
    tbody() {
      return this.users || [];
    },
  },
  methods: {
    get_users() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "users",
            method: "get",
          },
        })
        .then((res) => {
          this.users = Object.assign([], res.data.data);
        }).catch(err=> {
          console.log(err.response)
        })
    },
    edit_employee(item, ind) {
      this.$emit("edit_employee", { item: item, index: ind });
    },
    get_delete_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_employee() {
      if (this.delete_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `users/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            console.log(res);
            this.get_users();
            this.delete_dialog = false;
            this.delete_loader = false;
          }).catch(err=> {
            console.log(err.response)
          })
      }
    },
  },
  mounted() {
    this.get_users();
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter" && vm.delete_id && !vm.delete_loader) {
        vm.delete_employee();
      }
    };
  },
};
</script>
