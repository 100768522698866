<template>
  <v-container>
    <v-skeleton-loader
      class="mt-10"
      type="table"
      v-if="!table_loading"
    ></v-skeleton-loader>

    <v-card class="mt-10" v-if="table_loading">
      <v-data-table
        mobile-breakpoint="0"
        :items="reports"
        :items-per-page="5"
        :search="search"
        :headers="headers"
        :no-data-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        :no-results-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        loading-text="جارى التحميل"
        :footer-props="this.$store.state.dataTableFooterProps"
      >
        <template slot="body.prepend">
          <tr>
            <td colspan="5">
              <v-text-field
                class="pa-0 ma-0 mb-4"
                hide-details
                :label="!$store.state.ar ? public_key.search_input : 'بحث'"
                v-model="search"
                append-icon="search"
                full-width
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:[`item.employee`]="{ item }">
          <v-btn
            :to="{
              name: 'employee',
              query: { id: item.employee.id, date: item.date },
            }"
            icon
          >
            <v-icon> visibility </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            @click="toggle_status(item, 'status')"
            x-small
            depressed
            fab
            color="success"
          >
            <v-icon> check </v-icon>
          </v-btn>
          <v-btn
            @click="toggle_status(item, 'status')"
            class="ms-2"
            x-small
            depressed
            fab
            color="error"
          >
            <v-icon> close </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.paid`]="{ item }">
          <v-switch
            @click="toggle_status(item, 'paid')"
            dense
            color="success"
            :label="
              item.paid
                ? keys.employee_status_active
                : keys.employee_status_inactive
            "
            v-model="item.paid"
          >
          </v-switch>
        </template>
        <!--/ employee status -->
      </v-data-table>
      <!--/ v-card (table )-->
    </v-card>
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "reports_approval",
  data() {
    return {
      menu: false,
      status: false,
      search: "",
      table_loading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      reports: [],
      headers: [
        {
          align: "center",
          text: " اسم الموظف",
          value: "employee.name",
        },
        {
          text: "الشهر",
          value: "date",
          align: "center",
        },

        {
          text: "معاينة الموظف",
          value: "employee",
          align: "center",
        },
      ],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.lang("employees");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
  },
  components: {},
  methods: {
    get_reports() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "review",
            method: "get",
          },
        })
        .then((res) => {
          this.reports = res.data.data;
          this.table_loading = true;
        });
    },
    toggle_status(item, type) {
      let value;

      if (type == "paid") {
        value = item.paid ? 1 : 0;
      } else {
        value = item.status == 1 ? 0 : 1;
      }

      this.$store
        .dispatch("public__request", {
          config: {
            url: `review/${item.id}/toggle`,
            method: "post",
          },
          data: {
            key: type,
            value: value,
          },
        })
        .then((res) => {
          this.get_reports();
        });
    },
  },
  mounted() {
    let paid = {
      text: "الدفع",
      value: "paid",
      align: "center",
    };
    let actions = {
      text: "اجراءات",
      value: "actions",
    };
    if (this.$store.state.user_staff_timing_obj.type == "manager") {
      this.headers.push(paid);
      this.headers.push(actions);
    } else if (this.$store.state.user_staff_timing_obj.type == "accountant") {
      this.headers.push(paid);
    }
    this.get_reports();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  thead {
    background-color: #11556b;
    th {
      color: #fff !important;
      font-weight: 500;
    }
  }
}
</style>
