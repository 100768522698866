<template>
  <div>
    <v-btn
      v-if="!toggle_form"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-card class="mt-10" v-if="toggle_form">
      <v-card-text>
        <v-form ref="form" v-model="form">
          <v-row class="mb-3">
            <v-col cols="6" sm="3">
              <v-file-input
                :rules="file_index > -1 ? [] : rules.attach"
                v-model="attachment.attachment"
              >
                <template slot="label">
                  <strong v-if="file_index == -1" class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.files : "الملف" }}
                </template>
              </v-file-input>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-select
                :rules="rules.attach_type"
                v-model="attachment.attachment_type_id"
                :items="[1]"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.file_type : "نوع الملف" }}
                </template>
              </v-select>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.desc"
                v-model="attachment.description"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.descriptions : "الوصف" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-textarea
                v-model="attachment.notes"
                rows="1"
                :label="!$store.state.ar ? keys.notes : 'الملاحظات'"
              ></v-textarea>
            </v-col>
            <!--/col -->
          </v-row>
          <div class="text-end">
            <v-btn
              :disabled="save_loader"
              :loading="save_loader"
              @click="save_attch()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
            </v-btn>
            <v-btn @click="resetForm()" class="me-2" color="lightGray" outlined>
              <v-icon class="me-1" color="primary">delete</v-icon>
              {{ !$store.state.ar ? public_key.reset_btn : "مسح" }}
            </v-btn>
          </div>
        </v-form>
        <!--/ form -->
      </v-card-text>
    </v-card>
    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ الملف بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["keys", "public_key"],
  name: "FilesForm",
  data() {
    return {
      snackbar_err: false,
      snackbar: false,
      form: false,
      file_index: -1,
      toggle_form: false,
      save_loader: false,
      modal: false,
      date: null,
      time: null,
      menu1: false,
      modal1: false,
      time: null,
      menu2: false,
      modal2: false,
      attachment: {
        attachment_type_id: null,
        attachment: null,
        notes: null,
        description: null,
      },

      rules: {
        attach: [(v) => !!v || "الملف مطلوب"],
        attach_type: [(v) => !!v || "برجاء اختيار نوع الملف"],
        desc: [(v) => !!v || "الوصف مطلوب"],
      },
    };
  },
  methods: {
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      if (!this.toggle_form) {
        // if form is close back the employee index to default
        this.file_index = -1;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    edit_file(ev) {
      this.$set(this.attachment, "id", ev.item.id);
      this.$set(this.attachment, "attachment_type_id", ev.item.type.id);
      this.$set(this.attachment, "notes", ev.item.notes);
      this.$set(this.attachment, "description", ev.item.description);
      this.file_index = ev.index; // set employee index
      this.toggle_form = true;
    },
    save_attch() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;
        const formData = new FormData();

        Object.keys(this.attachment).forEach((e, i) => {
          formData.append(e, this.attachment[e]);
        });

        this.$store
          .dispatch("public__request", {
            config: {
              url:
                this.file_index > -1
                  ? `attachments/${this.attachment.id}`
                  : "attachments",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.$emit("get_files");
            this.resetForm();
            this.save_loader = false;
            this.snackbar_err = false;
            this.snackbar = true;
          })
          .catch((err) => {
            this.snackbar = false;
            this.snackbar_err = true;
            this.save_loader = false;
            this.$refs.form.validate();
          });
      } else {
        this.snackbar = false;
        this.snackbar_err = true;
      }
    },
    resetForm() {
      this.toggle_btn_form();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>
