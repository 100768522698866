<template>
  <div>
    <v-btn
      v-if="!toggle_form"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-card class="mt-10" v-if="toggle_form">
      <v-card-text>
        <v-form ref="form" v-model="form">
          <v-row class="mb-3">
            <v-col cols="6" sm="3">
              <v-select
                v-model="employee_approvals.user_id"
                :rules="rules.employee"
                :items="users"
                :loading="users.length == 0"
                item-text="name"
                item-value="id"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  الموظف
                </template>
              </v-select>
            </v-col>

            <v-col cols="6" sm="3">
              <v-text-field
                v-model="employee_approvals.description"
                :rules="rules.desc"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.descriptions : "الوصف" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="rules.date"
                    v-model="employee_approvals.date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      تاريخ الموافقة
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="employee_approvals.date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!--/col -->

            <!--/col -->
          </v-row>
          <div class="text-end">
            <v-btn
              :disabled="save_loader"
              :loading="save_loader"
              @click="save_attch()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
            </v-btn>
            <v-btn @click="resetForm()" class="me-2" color="lightGray" outlined>
              <v-icon class="me-1" color="primary">delete</v-icon>
              {{ !$store.state.ar ? public_key.reset_btn : "مسح" }}
            </v-btn>
          </div>
        </v-form>
        <!--/ form -->
      </v-card-text>
    </v-card>
    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ الملف بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["keys", "public_key"],
  name: "ApprovalForm",
  data() {
    return {
      users: [],
      snackbar_err: false,
      snackbar: false,
      form: false,
      item_index: -1,
      toggle_form: false,
      save_loader: false,
      menu2: false,
      employee_approvals: {
        user_id: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        description: null,
      },

      rules: {
        employee: [(v) => !!v || "الموظف مطلوب"],
        date: [(v) => !!v || "برجاء اختيار نوع التاريخ"],
        desc: [(v) => !!v || "الوصف مطلوب"],
      },
    };
  },
  methods: {
    get_users() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "users",
            method: "get",
          },
        })
        .then((res) => {
          this.users = Object.assign([], res.data.data);
        });
    },
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      if (!this.toggle_form) {
        // if form is close back the employee index to default
        this.item_index = -1;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    edit_item(ev) {
      console.log(ev);
      this.item_index = ev.index;
      this.$set(this.employee_approvals, "date", ev.item.date);
      this.$set(this.employee_approvals, "description", ev.item.description);
      this.$set(this.employee_approvals, "user_id", ev.item.employee.id);
      this.$set(this.employee_approvals, "id", ev.item.id);
      this.toggle_form = true;
    },
    save_attch() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;
        const formData = new FormData();
        Object.keys(this.employee_approvals).forEach((e, i) => {
          formData.append(e, this.employee_approvals[e]);
        });

        this.$store
          .dispatch("public__request", {
            config: {
              url: this.item_index > -1  ? `employee_approvals/${this.employee_approvals.id}` : "employee_approvals",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.$emit("get_items");
            this.resetForm();
            this.save_loader = false;
            this.snackbar_err = false;
            this.snackbar = true;
          })
          .catch((err) => {
            this.snackbar = false;
            this.snackbar_err = true;
            this.save_loader = false;
            this.$refs.form.validate();
            console.log(err.response)
          });
      } else {
        this.snackbar = false;
        this.snackbar_err = true;
      }
    },
    resetForm() {
      this.toggle_btn_form();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.get_users();
  },
};
</script>
