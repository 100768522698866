<template>
  <div>
    <v-skeleton-loader
      type="article"
      class="mt-10"
      v-if="!infoLoading"
    ></v-skeleton-loader>
    <v-card v-if="infoLoading" class="mt-10">
      <v-card-text class="pa-8">
        <v-row>
          <v-col>
            <p class="mb-4">
              <span class="font-weight-bold black--text me-3">
                {{
                  !$store.state.ar ? keys.employee_name : "اسم الموظف"
                }}
                :
              </span>
              <span>{{ info.name }}</span>
            </p>

            <p class="mb-4">
              <span class="font-weight-bold black--text me-3">
                {{
                  !$store.state.ar ? keys.phone_number : "رقم الهاتف"
                }}
                :
              </span>
              <span>{{ info.phone }}</span>
            </p>
            <p>
              <span class="font-weight-bold black--text me-3"
                >{{
                  !$store.state.ar
                    ? keys.identifier_number
                    : "رقم الهوية"
                }}
                :
              </span>
              <span>212615945694564</span>
            </p>
          </v-col>
          <!--/col -->

          <v-col>
            <p class="mb-4">
              <span class="font-weight-bold black--text me-3"
                >{{
                  !$store.state.ar
                    ? keys.employee_type
                    : " نوع الموظف"
                }}:
              </span>
              <span>{{ info.type }}</span>
            </p>

            <p class="mb-4">
              <span class="font-weight-bold black--text me-3"
                >{{ !$store.state.ar ? keys.job : "الوظيف" }} :
              </span>
              <span>{{ info.job }}</span>
            </p>
            <p>
              <span class="font-weight-bold black--text me-3"
                >{{ !$store.state.ar ? keys.city : "البلد" }} :
              </span>
              <span>{{ info.country }}</span>
            </p>
          </v-col>
          <!--/col -->
          <v-col>
            <p class="mb-4">
              <span class="font-weight-bold black--text me-3"
                >{{
                  !$store.state.ar
                    ? keys.email
                    : "البريد الالكترونى"
                }}:
              </span>
              <span>{{ info.email }}</span>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "EmployeeInfo",
  props: ["keys", "goals_types", "check_params"],
  data() {
    return {
      infoLoading: false,
      info: this.$store.state.user_staff_timing_obj,
    };
  },
  methods: {
    get_info() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `users/${this.$route.query.id}`,
            method: "get",
          },
        })
        .then((res) => {
          this.info = res.data.data;
          this.infoLoading = true;
        });
    },
  },
  mounted() {
    if (this.check_params) {
      this.get_info();
    } else {
      this.infoLoading = true;
    }
  },
};
</script>
