var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.table_loading)?_c('v-skeleton-loader',{staticClass:"mt-10",attrs:{"type":"table"}}):_vm._e(),(_vm.table_loading)?_c('v-card',{staticClass:"mt-10"},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.protocols,"items-per-page":5,"no-data-text":!_vm.$store.state.ar ? _vm.public_key.table_empty : 'لايوجد بيانات',"no-results-text":!_vm.$store.state.ar ? _vm.public_key.table_empty : 'لايوجد بيانات',"loading-text":"جارى التحميل","footer-props":this.$store.state.dataTableFooterProps},scopedSlots:_vm._u([{key:"item.members",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.members.length > 15 ? item.members.slice(0, 15) + "..." : item.members)+" ")]}},{key:"item.topics",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.topics.length > 15 ? item.topics.slice(0, 15) + "..." : item.topics)+" ")]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notes.length > 15 ? item.notes.slice(0, 15) + "..." : item.notes)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.show_item(item)}}},[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('template',{slot:"body.prepend"},[_c('tr',[_c('td',{attrs:{"colspan":"11"}},[_c('v-text-field',{staticClass:"pa-0 ma-0 mb-4",attrs:{"hide-details":"","label":!_vm.$store.state.ar ? _vm.public_key.search_input : 'بحث',"append-icon":"search","full-width":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])],2)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("عرض البيانات ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('div',[_c('h4',{staticClass:"black--text mb-2"},[_vm._v("المستخدمين")]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.item.members))])]),_c('v-divider',{staticClass:"my-4"}),_c('div',[_c('h4',{staticClass:"black--text mb-2"},[_vm._v("المواضيع")]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.item.topics))])]),_c('v-divider',{staticClass:"my-4"}),_c('div',[_c('h4',{staticClass:"black--text mb-2"},[_vm._v("الملخص")]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.item.notes))])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" الغاء ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }