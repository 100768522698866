<template>
  <div>
    <v-skeleton-loader
      type="article"
      class="mt-10"
      v-if="!loaded"
    ></v-skeleton-loader>
    <v-form ref="form" v-if="loaded"
      >>
      <v-card class="mt-10">
        <v-card-title>{{
          !$store.state.ar ? keys.basic_info_title : "الأعدادت"
        }}</v-card-title>
        <v-card-text>
          <v-row class="mb-3">
            <v-col cols="6" sm="3">
              <v-text-field :rules="rules" v-model="association.name">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.basic_info_title : "اسم الشركة" }}
                </template>
              </v-text-field>
              <!--/ field -->
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-file-input
                :label="!$store.state.ar ? keys.logo : 'لوجو'"
                prepend-icon=""
                prepend-inner-icon="attach_file"
              >
              </v-file-input>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field :rules="rules" v-model="association.phone">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.phone_number : "رقم الهاتف" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field :rules="rules" v-model="association.whatsapp">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.whatsapp : "الواتساب" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-switch
                v-model="association.send_reports_without_agree"
                :label="
                  !$store.state.ar
                    ? keys.send_report
                    : 'ارسال التقارير بدون موافقة'
                "
              ></v-switch>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-switch
                v-model="association.save_future_hours"
                :label="
                  !$store.state.ar
                    ? keys.record_future_hours
                    : 'تسجيل ساعات مستقبلية'
                "
              ></v-switch>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-switch
                v-model="association.save_past_date"
                :label="
                  !$store.state.ar
                    ? keys.record_future_hours
                    : 'تسجيل تاريخ قديم'
                "
              ></v-switch>
            </v-col>
            <!--/col -->
          </v-row>

          <div class="text-end">
            <v-btn
              @click="update_profile()"
              class="me-2"
              color="lightGray"
              outlined
              :disabled="save_loading"
              :loading="save_loading"
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
            </v-btn>
            <v-btn class="me-2" color="lightGray" outlined>
              <v-icon color="primary" class="me-1">delete</v-icon>
              {{ !$store.state.ar ? public_key.reset_btn : "مسح" }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <!--/ basic informations -->
    </v-form>

    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ الاعدادت بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["keys", "public_key"],
  name: "BasicInfo",
  data() {
    return {
      snackbar:false,
      loaded: false,
      rules: [(v) => !!v || "مطلوب"],
      association: {
        name: "",
        image: null,
        phone: "",
        whatsapp: "",
        save_future_hours: 0,
        save_past_date: 0,
        send_reports_without_agree: 0,
      },
      save_loading: false,
    };
  },
  methods: {
    get_association_info() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `association/${this.$store.state.user_staff_timing_obj.association_id}`,
            method: "get",
          },
        })
        .then((res) => {
          this.association = Object.assign({}, res.data.data);
          this.loaded = true;
          console.log(res);
        });
    },
    update_profile() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `profile/settings`,
              method: "post",
            },
            data: this.association,
          })
          .then((res) => {
            this.snackbar = true;
            this.save_loading = false;
          });
      }
    },
  },
  mounted() {
      this.get_association_info();
  },
};
</script>
