<template>
  <v-container>
    <FilesTable
      ref="File_table"
      :keys="keys"
      :public_key="public_key"
      @edit_file="edit_file($event)"
    ></FilesTable>
    <FilesForm
      ref="formFiles"
      @get_files="get_files()"
      :keys="keys"
      :public_key="public_key"
    ></FilesForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import FilesTable from "@/components/files/table";
import FilesForm from "@/components/files/form";
export default {
  name: "files",
  data() {
    return {
      file_index: -1,
      file_item: null,
      modal: false,
      date: null,
      time: null,
      menu1: false,
      modal1: false,
      time: null,
      menu2: false,
      modal2: false,
    };
  },
  computed: {
    keys() {
      return this.$store.getters.lang("files");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
  },
  components: {
    FilesTable,
    FilesForm,
  },
  methods: {
    get_files() {
      this.$refs.File_table.get_files();
    },
    edit_file(ev) {
      this.$refs.formFiles.edit_file(ev);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  thead {
    background-color: #11556b;
    th {
      color: #fff !important;
      font-weight: 500;
    }
  }
}
</style>
