<template>
  <div>
    <v-navigation-drawer
      color="primary"
      class="app-menu"
      v-model="$store.state.menu"
      app
      :right="rtl"
      width="200"
    >
      <v-list nav dense class="pt-10 list d-done">
        <v-list-item
          v-if="$store.state.user_staff_timing_obj.type == 'manager'"
          class="white--text"
          to="/employees"
          link
        >
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">group</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">{{
            !$store.state.ar ? keys.employees : "الموظفين"
          }}</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
        <v-list-item class="white--text" to="/employee" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">person</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">{{
            !$store.state.ar ? keys.employee : "الموظف"
          }}</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
        <v-list-item class="white--text" to="/files" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">cloud_upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">{{
            !$store.state.ar ? keys.files : "الملفات"
          }}</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
        <v-list-item class="white--text" to="/protocols" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">hub</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">{{
            !$store.state.ar ? keys.protcols : "البروتوكولز"
          }}</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
        <v-list-item
          v-if="$store.state.user_staff_timing_obj.type == 'manager'"
          class="white--text"
          to="/settings"
          link
        >
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">{{
            !$store.state.ar ? keys.settings : "الاعدادت"
          }}</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="$store.state.user_staff_timing_obj.type == 'manager'"
          class="white--text"
          to="/employees_approval"
          link
        >
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">
            {{
              !$store.state.ar ? keys.employee_approval : "موافقةالموظفين"
            }}</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/reports_approval" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">
            {{ !$store.state.ar ? keys.reports_approval : "  موافقة التقارير" }}
          </v-list-item-title>
        </v-list-item>
        <!--/ list item -->
      </v-list>

      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
    keys() {
      return this.$store.getters.lang("menu");
    },
  },
};
</script>
