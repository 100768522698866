<template>
  <v-form ref="form">
    <v-card v-if="!goalTypes_loading" class="mt-10">
      <v-skeleton-loader v type="article"></v-skeleton-loader>
    </v-card>

    <v-card v-if="goalTypes_loading" class="mt-10">
      <v-card-title>
        {{ !$store.state.ar ? keys.targets_types : "انواع الاهداف" }}
      </v-card-title>
      <!--/card title -->
      <v-card-text class="pa-8">
        <v-row
          v-show="goal.deleted == 0"
          class="align-center"
          v-for="(goal, i) in goalTypes"
          :key="i"
        >
          <v-col cols="5" sm="4">
            <v-select
              :rules="goal.deleted == 0 ? select_rules : []"
              :items="all_goals"
              item-value="id"
              item-text="name"
              :loading="all_goals.length == 0"
              v-model="goal.goal_id"
              label="اختيار الهدف"
            ></v-select>
          </v-col>
          <v-col cols="5" sm="4">
            <v-text-field
              :rules="goal.deleted == 0 ? rules : []"
              v-model="goal.name"
              :label="!$store.state.ar ? keys.description : 'وصف'"
            ></v-text-field
          ></v-col>
          <v-col>
            <v-btn @click="delete_goal(i)" color="error" icon>
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--/row -->
      </v-card-text>
      <!--/ card text -->
      <v-card-actions class="px-8 pb-8">
        <v-btn
          @click="
            goalTypes.push({ name: '', goal_id: null, deleted: 0, id: null })
          "
          outlined
          color="primary"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="save_loading"
          :loading="save_loading"
          @click="validate()"
          class="me-2"
          color="lightGray"
          outlined
        >
          <v-icon class="me-1" color="primary">save</v-icon>
          {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
        </v-btn>
      </v-card-actions>
      <!--/ card actions -->
    </v-card>

    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ الانواع بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>
  </v-form>
  <!--/form -->
</template>

<script>
export default {
  name: "Golas_types",
  props: ["keys", "all_goals", "public_key"],
  data() {
    return {
      snackbar: false,
      snackbar_err: false,
      save_loading: false,
      goalTypes_loading: false,
      goalTypes: [],
      goalTypes_obj: {},
      rules: [(v) => !!v || "الوصف مطلوب"],
      select_rules: [(v) => !!v || "برجاء اختيار هدف"],
    };
  },
  computed: {},
  methods: {
    get_goalTypes() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "goalTypes",
            method: "get",
          },
        })
        .then((res) => {
          let goalTypes = res.data.data;

          goalTypes.forEach((e, i) => {
            goalTypes[i].deleted = 0;
          });
          this.goalTypes_loading = true;
          this.goalTypes = Object.assign([], goalTypes);
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        const goalTypes = [];

        this.goalTypes.forEach((e, i) => {
          if (e.deleted != 1 && e.name && e.goal_id) {
            goalTypes.push(e);
          } else if (e.deleted == 1 && e.id) {
            goalTypes.push(e);
          }
        });

        this.$store
          .dispatch("public__request", {
            config: {
              url: "goalTypes",
              method: "post",
            },
            data: {
              types: goalTypes,
            },
          })
          .then((res) => {
            this.snackbar = true;
            this.save_loading = false;
            this.snackbar_err = false;
          });
      } else {
        this.snackbar = false;
        this.snackbar_err = true;
      }
    },
    delete_goal(index) {
      this.$set(this.goalTypes[index], "deleted", 1);
    },
  },
  mounted() {
    this.get_goalTypes();
  },
};
</script>
