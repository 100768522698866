<template>
  <v-app>
    <AsideMenu v-if="$route.name != 'login' && $store.getters.loggedin"></AsideMenu>
    <AppHeader v-if="$route.name != 'login' && $store.getters.loggedin">></AppHeader>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
import AppHeader from "./layout/header.vue";

import AsideMenu from "./layout/AsideMenu.vue";

export default {
  name: "App",
  data: () => ({
    page_keys: {
      pages: [],
    },
  }),
  components: {
    AppHeader,
    AsideMenu
  },
  created() {

  },
};
</script>
