import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

import he_lang from "@/json/he.json";

export default new Vuex.Store({
  state: {
    def_path: "https://edarra.com/tas2",
    dataTableFooterProps: {
      showFirstLastPage: true,
      "items-per-page-text": "דפים",
    },
    user_staff_timing_obj:
      JSON.parse(localStorage.getItem("user_staff_timing_obj")) || null,
    token: localStorage.getItem("token_staff_timing") || null,
    menu: true,
    lang: he_lang,
    ar: false,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    user_staff_timing_obj(state, user) {
      state.user_staff_timing_obj = user;
    },
    lang: (state) => (section) => {
      let lang = state.lang;
      return lang[section] || [];
    },
    public_lang(state) {
      let lang = state.lang;
      return lang["public"] || [];
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
      console.log(token);
    },
    logout(state) {
      state.token = null;
      localStorage.removeItem("user_staff_timing_obj");
      localStorage.removeItem("token_staff_timing");
    },
    user_staff_timing_obj(state,user) {
      state.user_staff_timing_obj = user;
    },
    public__request(state, item) {
      state.pages[item.name] = item.data;
    },
    download_file(state, fileName) {
      console.log(fileName);
      axios({
        url: fileName, // File URL Goes Here
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          // var FILE = window.URL.createObjectURL(new Blob([res.data]));

          // var docUrl = document.createElement("x");
          // docUrl.href = FILE;
          // docUrl.setAttribute("download", "file.pdf");
          // document.body.appendChild(docUrl);
          // docUrl.click();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "/auth/login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            const user = res.data.data.user_data;
            localStorage.setItem("token_staff_timing", token);
            localStorage.setItem("user_staff_timing_obj", JSON.stringify(user));

            
            context.commit("login", token);
            context.commit("user_staff_timing_obj", user);
            resolve(res);
            // console.log(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // user_informations(context) {
    //   return new Promise((resolve, reject) => {
    //     var config = {
    //       method: "get",
    //       url: "/auth/profile",
    //       headers: {
    //         Accept: "application/json",
    //         Authorization: `Bearer ${context.state.token}`,
    //       },
    //     };
    //     axios(config)
    //       .then((res) => {
    //         const user = res.data.data;
    //         localStorage.setItem("user_staff_timing_obj", JSON.stringify(user));
    //         context.commit("user_staff_timing_obj", user);
    //         resolve(res);
    //       })
    //       .catch((err) => {
    //         console.log(err.response);
    //         reject(err);
    //       });
    //   });
    // },

    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };

        axios(config)
          .then((res) => {
            resolve(res);
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});
