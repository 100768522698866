<template>
  <div class='mb-10'>
    <v-btn
      v-if="!toggle_form && is_send"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>

    <v-card v-if="toggle_form && is_send" class="mt-10 mb-10">
      <v-card-text>
        <v-form ref="form">
          <v-row class="mb-4">
            <v-col cols="6" sm="3">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="report.report_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="rules.required"
                    v-model="report.report_date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    min-width="200"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ !$store.state.ar ? keys.date : "التاريخ" }}
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="report.report_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    الغاء
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(report.report_date)"
                  >
                    حفظ
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!--/ col -->

            <v-col cols="6" sm="3">
              <v-text-field disabled :value="day">
                <template slot="label">
                  {{ !$store.state.ar ? keys.day : "اليوم" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="report.start_time"
                prepend-icon="mdi-clock-time-four-outline"
                type="text"
                :rules="rules.start_time"
                @input="check_time({ value: $event, prop: 'start_time' })"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.start_time : "بداية  الوقت" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field
                v-model="report.end_time"
                prepend-icon="mdi-clock-time-four-outline"
                :rules="rules.end_time"
                type="text"
                @input="check_time({ value: $event, prop: 'end_time' })"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.end_time : "نهاية  الوقت" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field :value="total_hours" type="number" disabled>
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.total_hours : "اجمالى الساعات" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-select
                :rules="rules.required"
                v-model="report.goal_id"
                :items="goals"
                item-value="id"
                item-text="name"
                :loading="goals.length == 0"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.target : "الهدف" }}
                </template>
              </v-select>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-select
                :rules="rules.required"
                v-model="report.goal_type_id"
                :items="goals_types"
                item-value="id"
                item-text="name"
                :loading="goals_types.length == 0"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.target_type : "نوع الهدف" }}
                </template>
              </v-select>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.required"
                v-model="report.operation_description"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.potency : "الفاعلية" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-select
                :rules="rules.required"
                v-model="report.operation_id"
                :loading="operations.length == 0"
                :items="operations"
                item-value="id"
                item-text="name"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.potency_type : "نوع الفاعلية" }}
                </template>
              </v-select>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-select
                :rules="rules.members"
                v-model="report.members"
                multiple
                :loading="members.length == 0"
                :items="members"
                item-value="id"
                item-text="name"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.subscribers : "المشتركين" }}
                </template>
              </v-select>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field
                readonly
                type="text"
                :value="create_attachments.length"
                :label="!$store.state.ar ? public_key.files : 'مرفقات'"
                @click="
                  $emit('open_attach_dialog', {
                    table: false,
                    images: [],
                  })
                "
              >
                <template slot="append-outer">
                  <v-btn
                    @click="
                      $emit('open_attach_dialog', {
                        table: false,
                        images: [],
                      })
                    "
                    fab
                    small
                    depressed
                    color="primary"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-textarea v-model="report.notes" rows="1">
                <template slot="label">
                  {{ !$store.state.ar ? keys.details : "التفاصيل" }}
                </template>
              </v-textarea>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.required"
                v-model="report.travel_to"
                value="5"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.travel_form : "السفر من " }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->
            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.required"
                v-model="report.travel_from"
                value="5"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.travel_to : "السفر الى " }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->

            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.required"
                v-model="report.total_kilometers"
                value="5"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ !$store.state.ar ? keys.total_km : "اجمالى الكيلومتر" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col -->
          </v-row>
          <div class="text-end">
            <v-btn
              @click="save()"
              :loading="save_loader"
              :disabled="save_loader"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
            </v-btn>
            <v-btn
              @click="$refs.form.reset(), $refs.form.resetValidation()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon color="primary" class="me-1">delete</v-icon>
              {{ !$store.state.ar ? public_key.reset_btn : "حذف" }}
            </v-btn>
            <v-btn
              @click="toggle_btn_form()"
              class="me-2"
              color="lightGray"
              outlined
            >
              {{ !$store.state.ar ? public_key.back_form : "رجوع" }}
              <v-icon small class="me-1">arrow_back</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ التقرير</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: [
    "keys",
    "public_key",
    "goals",
    "goals_types",
    "operations",
    "members",
    "create_attachments",
    "is_send"
  ],
  name: "ReportForm",
  data() {
    return {
      toggle_form:false,
      snackbar: false,
      snackbar_err: false,
      save_loader: false,
      modal2: false,
      time: null,
      menu1: false,
      modal1: false,
      modal: false,
      report: {
        report_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        start_time: null,
        end_time: null,
        goal_id: null,
        goal_type_id: null,
        operation_id: null,
        total_kilometers: null,
        operation_description: null,
        notes: null,
        travel_from: null,
        travel_to: null,
        report_day: this.day,
        members: [],
      },
      rules: {
        required: [(v) => !!v || "مطلوب"],
        members: [(v) => !(v.length == 0) || "مطلوب"],
        start_time: [
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
        ],
        end_time: [
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
          (v) => !this.check_end_time || "يجب ان يكون اكبر من البداية",
        ],
      },
    };
  },
  watch: {
    report: {
      handler(val) {
        this.report.report_day = this.day;
      },
      deep: true,
    },
  },
  computed: {
    day() {
      let date = new Date(this.report.report_date);
      let num_day = date.getDay();
      let day;
      switch (num_day) {
        case 1:
          day = "الأثنين";
          break;
        case 2:
          day = "الثلاثاء";
          break;
        case 3:
          day = "الاربعاء";
          break;
        case 4:
          day = "الخميس";
          break;
        case 5:
          day = "الجمعة";
          break;
        case 6:
          day = "السبت";
          break;
        default:
          day = "الاحد";
          break;
      }
      return day;
    },
    check_end_time() {
      let start_time = new Date(
        `${this.report.report_date} ${this.report.start_time}:00`
      ).getHours();
      let end_time = new Date(
        `${this.report.report_date} ${this.report.end_time}:00`
      ).getHours();

      return end_time < start_time ? true : false;
    },
    total_hours() {
      let start_time = new Date(
        `${this.report.report_date} ${this.report.start_time}:00`
      ).getHours();
      let end_time = new Date(
        `${this.report.report_date} ${this.report.end_time}:00`
      ).getHours();

      return end_time - start_time;
    },
  },
  methods: {
    check_time(ev) {
      if (ev.value.length == 2) {
        this.report[ev.prop] = this.report[ev.prop] + ":";
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;

        const formData = new FormData();

        Object.keys(this.report).forEach((e, i) => {
          if (e == "members") {
            this.report.members.forEach((m, s) => {
              formData.append(`members[${s}]`, m);
            });
          } else if (e == "start_time") {
            formData.append(
              "start_time",
              `${this.report.report_date} ${this.report[e]}:00`
            );
          } else if (e == "end_time") {
            formData.append(
              "end_time",
              `${this.report.report_date} ${this.report[e]}:00`
            );
          } else {
            formData.append(e, this.report[e]);
          }
        });
        this.create_attachments.forEach((e, i) => {
          formData.append(`attachments[${i}]`, e);
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: `reports`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.save_loader = false;
            this.snackbar = true;
            this.$emit("save_report");
          });
      }
    },
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      // if (!this.toggle_form) {
      //   this.$refs.form.reset();
      //   this.$refs.form.resetValidation();
      // }
    },
  },
};
</script>
