import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

import employee from "@/views/employee.vue";
import employees from "@/views/employees.vue";
import files from "@/views/files.vue";
import settings from "@/views/settings.vue";
import protocols from "@/views/protocols.vue";
import employees_approval from "@/views/employees_approval.vue";
import reports_approval from "@/views/reports_approval.vue";

import login from "@/views/login.vue";

const PageNotFound = () => import("@/views/PageNotFound.vue");
const change_password = () => import("@/views/change_password.vue");

const routes = [
  {
    path: "/change_password",
    name: "change_password",
    component: change_password,
    meta: {
      title: "change_password",
      requiresAuth: true,
    },
  },
  {
    path: "/employee",
    name: "employee",
    component: employee,
    meta: {
      title: "employee",
      requiresAuth: true,
    },
  },
  {
    path: "/employees",
    name: "employees",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
    },
  },
  {
    path: "/files",
    name: "files",
    component: files,
    meta: {
      title: "files",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
    },
  },
  {
    path: "/protocols",
    name: "protocols",
    component: protocols,
    meta: {
      title: "protocols",
      requiresAuth: true,
    },
  },

  {
    path: "/employees_approval",
    name: "employees_approval",
    component: employees_approval,
    meta: {
      title: "employees_approval",
      requiresAuth: true,
    },
  },

  {
    path: "/reports_approval",
    name: "reports_approval",
    component: reports_approval,
    meta: {
      title: "reports_approval",
      requiresAuth: true,
    },
  },

  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    component: PageNotFound,
    meta: {
      title: "Error 404",
    },
  },
  ,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      next("/employee");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      if (store.state.user_staff_timing_obj.type != "manager") {
        if (to.name == "employees" || to.name == "settings" ||  to.name == "employees_approval") {
          next("*");
        } else {
          next();
        }
      } else {
        next();
      }
    }
  }
});
