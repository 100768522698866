<template>
  <div>
    <v-btn
      v-if="!toggle_form"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-form ref="form" class="mb-10">
      <v-card class="mt-10" v-if="toggle_form">
        <v-card-title>
          <span>
            {{
              !$store.state.ar ? keys.add_employee_title : "اضافة موظف"
            }}</span
          >
          <v-spacer></v-spacer>

          <v-switch
            v-model="status"
            :label="
              status
                ? !$store.state.ar
                  ? keys.employee_status_active
                  : ' فعال'
                : !$store.state.ar
                ? keys.employee_status_inactive
                : 'غير فعال'
            "
            hide-details
          ></v-switch>
          <!--/col (employee status)-->
        </v-card-title>
        <v-divider></v-divider>
        <!--/.card title -->

        <v-card-text>
          <v-row class="mb-3">
            <v-col cols="6" sm="3">
              <v-text-field :rules="rules.name" v-model="employee.name">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.employee_name : "اسم الموظف" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (employee name )-->

            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                :rules="rules.phone"
                v-model="employee.phone"
                @keypress="phone_exist = true"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.phone_number : "رقم الهاتف" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (phone  )-->

            <v-col cols="6" sm="3">
              <v-text-field :rules="rules.ssn" v-model="employee.ssn">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.identifier_number : "رقم الهوية" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (idintyfire )-->

            <v-col cols="6" sm="3">
              <v-select
                :rules="rules.employe_type"
                :items="employe_type"
                item-text="text"
                item-value="value"
                v-model="employee.type"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.employee_type : "نوع الموظف" }}
                </template>
              </v-select>
            </v-col>
            <!--/col (employee type)-->

            <v-col cols="6" sm="3">
              <v-select
                :loading="!sub_types_loading"
                :items="sub_types"
                item-text="name"
                item-value="id"
                v-model="employee.type_id"
                :rules="rules.type_id"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.employee_type : "نوع المشترك" }}
                </template>
              </v-select>
            </v-col>
            <!--/col (employee type)-->

            <v-col cols="6" sm="3">
              <v-text-field :rules="rules.job" v-model="employee.job">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.job : "الوظيفة" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (job)-->

            <v-col cols="6" sm="3">
              <v-text-field :rules="rules.country" v-model="employee.country">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.city : "البلد" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (city)-->

            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.email"
                v-model="employee.email"
                type="text"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.email : "البريد الالكتروني" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (email)-->

            <v-col cols="6" sm="3">
              <v-text-field
                v-model="employee.password"
                type="password"
                :rules="rules.password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.passowrd : "كلمة المرور" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (password )-->

            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.passowrd_conf"
                v-model="employee.password_confirmation"
                type="password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? keys.passowrd : "تكرار كلمة المرور" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (password confimarion )-->

            <v-col cols="6" sm="3">
              <v-text-field
                :rules="rules.price_hour"
                append-icon=""
                type="number"
                v-model="employee.price_hour"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ !$store.state.ar ? "سعر الساعة" : "سعر الساعة" }}
                </template>
              </v-text-field>
            </v-col>
            <!--/col (password )-->
            <v-col cols="6" sm="3">
              <v-text-field
                readonly
                type="text"
                :value="attachs_length"
                :label="!$store.state.ar ? public_key.files : 'الملفات'"
                @click="open_dialog()"
              >
                <template slot="append-outer">
                  <v-btn
                    @click="open_dialog()"
                    fab
                    x-small
                    depressed
                    color="primary"
                  >
                    <v-icon small>add</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <!--/col (attatchments )-->
          </v-row>
          <!--/ row -->

          <div class="text-end">
            <v-btn
              @click="$refs.form.reset(), $refs.form.resetValidation()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1">delete</v-icon>
              {{ !$store.state.ar ? public_key.reset_btn : "مسح" }}
            </v-btn>
            <v-btn
              :disabled="save_loading"
              :loading="save_loading"
              @click="validate()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
            </v-btn>

            <v-btn
              @click="toggle_btn_form()"
              class="me-2"
              color="lightGray"
              outlined
            >
              {{ !$store.state.ar ? public_key.back_form : "رجوع" }}
              <v-icon small class="me-1">arrow_back</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <!--/ form -->
    </v-form>

    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ الموظف بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["attachments", "keys", "public_key"],
  name: "employeeForm",
  data() {
    return {
      snackbar_err: false,
      employee_index: -1,
      snackbar: false,
      snackbar_success: false,
      save_loading: false,
      status: false,
      phone_exist: true,
      toggle_form: false,
      employee: {
        name: "",
        phone: "",
        password: "",
        password_confirmation: "",
        type: "",
        job: "",
        country: "",
        email: "",
        price_hour: "",
        type_id: "",
        ssn: "",
        images: [],
      },
      rules: {
        name: [(v) => !!v || "اسم الموظف مطلوب"],
        phone: [
          (v) => !!v || "رقم هاتف الموظف",
          (v) =>
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
              v
            ) || "رقم الهاتف غير صحيح",
          (v) => this.phone_exists || "رقم  الهاتف موجود مسبقا",
        ],
        ssn: [(v) => !!v || "رقم الهوية مطلوب"],
        employe_type: [(v) => !!v || "نوع الموظف مطلوب"],
        type_id: [(v) => !!v || "نوع المشترك مطلوب"],
        job: [(v) => !!v || " الوظيفة مطلوب"],
        country: [(v) => !!v || "البلد مطلوب"],
        email: [
          (v) => !!v || "البريد الالكتروني مطلوب",
          (v) =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "البريد غير صحيح",
        ],
        password: [(v) => !!v || "كلمة المرور مطلوب"],
        passowrd_conf: [
          (v) => this.password_match || "كلمة المرور غير متطابقة",
        ],
        price_hour: [(v) => !!v || " سعر الساعة مطلوب"],
      },
      sub_types: [],
      sub_types_loading: false,
    };
  },
  watch: {
    employee_index(newVal) {
      console.log(this.employee_data);
    },
  },
  computed: {
    employe_type() {
      return [
        {
          text: "مدير",
          value: "manager",
        },
        {
          text: "محاسب",
          value: "accountant",
        },
        {
          text: "موظف",
          value: "employee",
        },
      ];
    },
    password_match() {
      return this.employee.password_confirmation == this.employee.password;
    },
    phone_exists() {
      return this.phone_exist ? true : false;
    },
    attachs_length() {
      if (this.attachments.length > 0 && this.employee.images.length > 0) {
        return this.attachments.length + this.employee.images.length;
      } else if (
        this.attachments.length > 0 &&
        this.employee.images.length <= 0
      ) {
        return this.attachments.length;
      } else {
        return this.employee.images.length;
      }
    },
  },
  methods: {
    // get types select
    get_sub_types() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "types",
            method: "get",
          },
        })
        .then((res) => {
          this.sub_types = Object.assign([], res.data.data);
          this.sub_types_loading = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      if (!this.toggle_form) {
        // if form is close back the employee index to default
        this.employee_index = -1;
        this.employee.images = [];
        this.$emit("clear_file_input");
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    edit_employee(ev) {
      this.employee = Object.assign({}, ev.item); // set employee information into the from to edit him
      this.employee_index = ev.index; // set employee index
      this.toggle_form = true;
    },
    // save employee
    validate() {
      if (this.$refs.form.validate()) {
        // check if all inputs are validate
        this.save_loading = true;
        const formData = new FormData();

        Object.keys(this.employee).forEach((e, i) => {
          formData.append(e, this.employee[e]);
        });

        this.attachments.forEach((e, i) => {
          formData.append(`attachments[${i}]`, e);
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url:
                this.employee_index > -1
                  ? `users/${this.employee.id}`
                  : "users",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.save_loading = false;
            this.toggle_form = false;
            this.employee_index = -1;
            this.$emit("employee_save");
            this.$refs.form.reset();
            this.snackbar_success = true;
            this.snackbar_err = false;
          })
          .catch((err) => {
            this.save_loading = false;
            this.snackbar = true;
            this.snackbar_err = true;
            if (err.response.data.errors.phone) {
              this.$set(this, "phone_exist", false);
              this.$refs.form.validate();
            }
          });
      } else {
        this.snackbar_err = true;
      }
    },
    open_dialog() {
      // open attachments dialog and path current attachments paths if form gonna edit any employee
      let attach = this.employee.images || [];
      this.$emit("open_dialog", { current_attachments: attach });
    },
    // delete employee
  },
  created() {
    this.get_sub_types();
  },
};
</script>
