<template>
  <div>
    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ البروتوكول بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
    <v-card class="mt-10">
      <v-card-text>
        <v-tabs v-model="tabs">
          <v-tab>البروتكول</v-tab>
          <v-tab>موافقات</v-tab>
        </v-tabs>
        <!--/ tabs -->
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-form ref="form" class="pt-5">
              <v-text-field
                :rules="rules"
                v-model="protocols.members"
                dense
                outlined
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  المشتركين
                </template>
              </v-text-field>
              <v-text-field
                :rules="rules"
                v-model="protocols.topics"
                dense
                outlined
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  مواضيع الجلسة
                </template>
              </v-text-field>
              <v-text-field
                :rules="rules"
                v-model="protocols.notes"
                dense
                outlined
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  ملخص الجلسة
                </template>
              </v-text-field>

              <div class="text-end">
                <v-btn @click="clear()" class="me-2" color="lightGray" outlined>
                  <v-icon class="me-1">delete</v-icon>
                  {{ !$store.state.ar ? public_key.reset_btn : "مسح" }}
                </v-btn>
                <v-btn @click="save()" class="me-2" color="lightGray" outlined>
                  <v-icon class="me-1" color="primary">save</v-icon>
                  {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
                </v-btn>

                <v-btn
                  @click="$emit('close_protocol')"
                  class="me-2"
                  color="lightGray"
                  outlined
                >
                  {{ !$store.state.ar ? public_key.back_form : "رجوع" }}
                  <v-icon small class="me-1">arrow_back</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              class="mt-3"
              mobile-breakpoint="0"
              :headers="headers4"
              :items-per-page="5"
              :search="search"
              :items="agreements"
              :no-data-text="
                !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
              "
              :no-results-text="
                !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
              "
              loading-text="جارى التحميل"
              :footer-props="this.$store.state.dataTableFooterProps"
            >
              <template slot="body.prepend">
                <tr>
                  <td colspan="5">
                    <v-text-field
                      class="pa-0 ma-0 mb-4"
                      hide-details
                      :label="
                        !$store.state.ar ? public_key.search_input : 'بحث'
                      "
                      v-model="search"
                      append-icon="search"
                      full-width
                    ></v-text-field>
                  </td>
                </tr>
              </template>
              <!--/ employee status -->
            </v-data-table>
            <!--/ v-card (table )-->
          </v-tab-item>
        </v-tabs-items>
        <!--/ tabs items -->
      </v-card-text>
      <!--/ card text -->
    </v-card>
    <!--/ card -->
  </div>
</template>

<script>
export default {
  props: ["protocols", "agreements", "attachments", "public_key", "keys"],
  name: "Protocols",
  data() {
    return {
      tabs: 0,
      snackbar: false,
      search: "",
      rules: [(v) => !!v || "الحقل مطلوب"],
      form: {
        members: "",
        notes: "",
        topics: "",
      },
    };
  },
  computed: {
    headers4() {
      return [
        {
          text: !this.$store.state.ar ? this.keys.employee_name : " اسم الموظف",
          value: "employee.name",
          align: "center",
        },
        {
          text: !this.$store.state.ar
            ? this.keys.employee_type
            : " تاريخ الموافقة",
          value: "date",
          align: "center",
        },
        {
          text: !this.$store.state.ar ? this.keys.city : "وصف الموافقة",
          value: "description",
          align: "center",
        },
      ];
    },
  },
  methods: {
    save() {
      console.log(this.protocols.id);
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `protocol/${this.protocols.id}`,
              method: "post",
            },
            data: this.protocols,
          })
          .then((res) => {
            this.snackbar = true;
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    clear() {
      Object.assign(this.protocols, this.form);
    },
  },
};
</script>
