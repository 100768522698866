<template>
  <div>
    <v-skeleton-loader type="table" v-if="!table_loading"></v-skeleton-loader>
    <v-card v-if="table_loading" class="mt-10">
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="files"
        :search="search"
        :items-per-page="5"
        :no-data-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        :no-results-text="
          !$store.state.ar ? public_key.table_empty : 'لايوجد بيانات'
        "
        loading-text="جارى التحميل"
        :footer-props="this.$store.state.dataTableFooterProps"
      >
        <template slot="body.prepend">
          <tr>
            <td colspan="11">
              <v-text-field
                class="pa-0 ma-0 mb-4"
                hide-details
                :label="!$store.state.ar ? public_key.search_input : 'بحث'"
                v-model="search"
                append-icon="search"
                full-width
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <v-btn
            @click="
              $store.commit(
                'download_file',
                `${$store.state.def_path}/${item.attachment}`
              )
            "
            icon
            small
          >
            <v-icon small>file_download</v-icon>
          </v-btn>
          <!--/ note -->

          <v-btn
            :href="`${$store.state.def_path}/${item.attachment}`"
            target="_blank"
            icon
            small
          >
            <v-icon small>visibility</v-icon>
          </v-btn>
          <!--/ note -->

          <v-btn icon small @click="show_note(item.notes)">
            <v-icon small>edit_note</v-icon>
          </v-btn>
          <!--/ note -->

          <v-btn icon small @click="edit_file(item, index)">
            <v-icon small>edit</v-icon>
          </v-btn>
          <!--/ edit -->

          <v-btn icon small @click="get_delete_id(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
          <!--/ delete -->
        </template>
      </v-data-table>
      <!--/ v-card (table )-->
    </v-card>
    <!--/ v-card -->

    <v-dialog max-width="500" v-model="note_prop">
      <v-card>
        <v-card-title>عرض الملاحظات</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <p>
            {{ note_text }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="note_prop = false" color="red" text> الغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ note dialog -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف الملف ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_file()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </div>
</template>

<script>
export default {
  props: ["keys", "public_key"],
  name: "FilesTable",
  data() {
    return {
      table_loading: false,
      delete_id: null,
      delete_dialog: false,
      delete_loader: false,
      search: "",
      files: [],
      note_text: "",
      note_prop: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          align: "center",
          text: !this.$store.state.ar ? this.keys.file_type : "نوع الملف",
          value: "type.name",
        },
        {
          align: "center",
          text: !this.$store.state.ar ? this.keys.descriptions : "الوصف",
          value: "description",
        },
        {
          align: "center",
          text: !this.$store.state.ar
            ? this.keys.creation_date
            : "تاريخ الاضافة",
          value: "created_at",
        },
        {
          align: "center",
          text: !this.$store.state.ar ? this.keys.notes : "اجرءات",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    get_files() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "attachments",
            method: "get",
          },
        })
        .then((res) => {
          this.table_loading = true;
          this.files = Object.assign([], res.data.data);
        });
    },
    show_note(notes) {
      this.note_text = notes;
      this.note_prop = true;
    },
    get_delete_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_file() {
      if (this.delete_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `attachments/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.get_files();
            this.delete_dialog = false;
            this.delete_loader = false;
          })
          .catch((err) => {});
      }
    },
    edit_file(item, ind) {
      this.$emit("edit_file", { item: item, index: ind });
    },
  },
  mounted() {
    this.get_files();
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter" && vm.delete_id && !vm.delete_loader) {
        vm.delete_file();
      }
    };
  },
};
</script>
