<template>
  <v-container>
    <BasicInfo :keys="keys" :public_key="public_key"></BasicInfo>

    <Golas @goals="goals($event)" :keys="keys" :public_key="public_key"></Golas>
    <!--/Goals -->
    <GoalsTypes :public_key="public_key"  :keys="keys"  :all_goals="all_goals"></GoalsTypes>

    <Jobs :keys="keys" :public_key="public_key"></Jobs>
    <JobsTypes :keys="keys" :public_key="public_key"></JobsTypes>
    <Sessions :keys="keys" :public_key="public_key"></Sessions>
    <SettingsTypes :public_key="public_key" :keys="keys" class="mb-10"></SettingsTypes>
    <!--/card -->

    <!--/form -->
  </v-container>
  <!--/ container -->
</template>

<script>
import Golas from "@/components/settings/goals";
import BasicInfo from "@/components/settings/Basic_info";
import GoalsTypes from "@/components/settings/Goal_types";
import Jobs from "@/components/settings/Jobs";
import Sessions from "@/components/settings/Sessions";
import JobsTypes from "@/components/settings/Jobs_types";
import SettingsTypes from "@/components/settings/Settings_types";

export default {
  name: "settings",
  data() {
    return {
      all_goals: [],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.lang("settings");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
  },
  components: {
    Golas,
    BasicInfo,
    GoalsTypes,
    Jobs,
    Sessions,
    JobsTypes,
    SettingsTypes,
  },
  methods: {
    goals(ev) {
      this.all_goals = ev.goals;
    },
  },
};
</script>

<style lang="scss" scoped></style>
