<template>
  <v-form ref="form">
    <v-card v-if="!attachmentTypes_loading" class="mt-10">
      <v-skeleton-loader v type="article"></v-skeleton-loader>
    </v-card>

    <v-card v-if="attachmentTypes_loading" class="mt-10">
      <v-card-title>
        {{ !$store.state.ar ? keys.targets_title : "الأهداف" }}
      </v-card-title>
      <!--/card title -->
      <v-card-text class="pa-8">
        <v-row
          v-show="attachmentType.deleted == 0"
          class="align-center"
          v-for="(attachmentType, i) in attachmentTypes"
          :key="i"
        >
          <v-col cols="8" sm="6">
            <v-text-field
              :rules="attachmentType.deleted == 0 ? rules : []"
              v-model="attachmentType.name"
              :label="!$store.state.ar ? keys.description : 'وصف'"
            ></v-text-field
          ></v-col>
          <v-col>
            <v-btn @click="delete_attachmentType(i)" color="error" icon>
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--/row -->
      </v-card-text>
      <!--/ card text -->
      <v-card-actions class="px-8 pb-8">
        <v-btn
          @click="attachmentTypes.push({ name: '', id: null, deleted: 0 })"
          outlined
          color="primary"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="save_loading"
          :loading="save_loading"
          @click="validate()"
          class="me-2"
          color="lightGray"
          outlined
        >
          <v-icon class="me-1" color="primary">save</v-icon>
          {{ !$store.state.ar ? public_key.save_btn : "حفظ" }}
        </v-btn>
      </v-card-actions>
      <!--/ card actions -->
    </v-card>

    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ انواع الملفات بنجاح</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_err">
      برجاء مراجعة البيانات
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>
  </v-form>
  <!--/form -->
</template>

<script>
export default {
  name: "Golas",
  props: ["keys","public_key"],
  data() {
    return {
      snackbar: false,
      snackbar_err: false,
      save_loading: false,
      attachmentTypes_loading: false,
      attachmentTypes: [],
      attachmentTypes_obj: {},
      rules: [(v) => !!v || "الوصف مطلوب"],
    };
  },
  computed: {},
  methods: {
    get_attachmentTypes() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "attachmentTypes",
            method: "get",
          },
        })
        .then((res) => {
          let attachmentTypes = res.data.data;
          attachmentTypes.forEach((e, i) => {
            attachmentTypes[i].deleted = 0;
          });

          this.$emit("attachmentTypes", { attachmentTypes: attachmentTypes });
          this.attachmentTypes_loading = true;
          this.attachmentTypes = Object.assign([], attachmentTypes);
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;

        const attachmentTypes = [];

        this.attachmentTypes.forEach((e, i) => {
          if (e.deleted != 1 && e.name) {
            attachmentTypes.push(e);
          } else if (e.deleted == 1 && e.id) {
            attachmentTypes.push(e);
          }
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: "attachmentTypes",
              method: "post",
            },
            data: {
              attachmentsTypes: attachmentTypes,
            },
          })
          .then((res) => {
            this.snackbar = true;
            this.save_loading = false;
            this.snackbar_err = false;
          });
      } else {
        this.snackbar = false;
        this.snackbar_err = true;
      }
    },
    delete_attachmentType(index) {
      this.$set(this.attachmentTypes[index], "deleted", 1);
    },
  },
  mounted() {
    this.get_attachmentTypes();
  },
};
</script>
