<template>
  <v-container>
    <employeesTable
      @edit_employee="edit_employee($event)"
      :keys="keys"
      :public_key="public_key"
      ref="table"
    ></employeesTable>
    <!--table -->
    <employeeForm
      :keys="keys"
      :public_key="public_key"
      :attachments="attachments"
      @open_dialog="open_dialog($event)"
      @employee_save="employee_save"
      @clear_file_input="clear_file_input()"
      ref="emForm"
    ></employeeForm>

    <attachments
      @close_dialog="dialog = false"
      @save_attach="save_attach($event)"
      :files="files"
      :dialog="dialog"
    ></attachments>
  </v-container>
  <!--/ container -->
</template>

<script>
import employeesTable from "@/components/employees/table";
import employeeForm from "@/components/employees/form";
import attachments from "@/components/employees/attachments";

export default {
  name: "employees",

  data() {
    return {
      employee_index: -1,
      employee_data: null,
      attachments: [],
      dialog: false,
      files: [],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.lang("employees");
    },
    public_key() {
      return this.$store.getters.public_lang;
    },
  },
  methods: {
    edit_employee(ev) {
      this.$refs.emForm.edit_employee(ev);
    },
    open_dialog(ev) {
      this.dialog = true;
      this.files = Object.assign([], ev.current_attachments);
    },
    save_attach(ev) {
      this.attachments = Object.assign([], ev.attach);
      this.dialog = false;
    },
    employee_save() {
      this.$refs.table.get_users();
      this.clear_file_input();
    },
    clear_file_input() {
      this.attachments = [];
    },
  },
  components: {
    employeesTable,
    employeeForm,
    attachments,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  thead {
    background-color: #11556b;
    th {
      color: #fff !important;
      font-weight: 500;
    }
  }
}
</style>
